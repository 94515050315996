/* Large desktop :1500px. */
@media (min-width: 1500px) {
    .about-image > img, .single-blog-img img, .member-img img {width: 100%}
    .slider-content p {
        width: 100%;
    }
}
@media (min-width: 1169px) and (max-width: 1500px) {
    .slider-content p {
        width: 54%;
    }
    .education-container {
        width: 992px;
    }
}
/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
    .about-container {
        width: 80%;
    }
    .about-row {
        display: block;
    }
    .about-size {
        width: 90%;
    }
    .slider-content h2{font-size: 60px}
    .about-image{margin-right: 0;}
    .about-image{margin-bottom: 50px}
    .image-bg img, .about-image img, .about-area.three .about-image{width: 100%}
    .single-service {padding: 30px 12px 25px}
    .single-fun::after{height: 140px; width: 140px}
    .single-fun {height: 200px; width: 200px}
    .single-fun-content {margin-top: 45px}
    .single-fun span{font-size: 26px; margin-bottom: 0}
    .single-fun h3{font-size: 35px; margin-bottom: 0}
    .project-title h3{
        font-size: 30px;
    }
    .portfolio-brief-info span {
        top: 70%;
    }
    .portfolio-menu ul li {margin-right: 28px; font-size: 15px;}
    .about-content {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }
    .two .single-service {padding: 40px 25px}
    .who-content {margin-left: 0; margin-right: 0}
    .who-content h3 {font-size: 30px; margin-bottom: 20px; padding-bottom: 20px;}
    p.bold {margin-bottom: 15px;font-weight: 700;font-size: 16px;}
    .testimonial-content p {font-size: 20px}
    .slider-content h4 {font-size: 50px}
    .single-service-three {padding: 30px 25px}
    .portfolio-hover h3 {font-size: 17px}
    ul.touch i {font-size: 16px; margin-right: 5px}
    ul.touch li {font-size: 12px; margin-bottom: 20px}
    .footer-social ul li {margin-right: 5px}
    .banner-content h2 {margin-top: 50px}
    .about-us-content {
        margin-top: 14px;
    }
    .skill-area .skill-bar-item:last-child{display: none}
    .knowledge-content p {margin-bottom: 30px} 
    .two .single-service {padding: 70px 25px}
    .about-area.three .who-content{padding-bottom: 75px; padding-top: 75px}
    .testimonial-area-three h6::before {left: 39%}
    .about-us-img {margin-left: -102px}
    .service-area.two .single-service {padding: 30px 25px}
    .about-content a {margin-top: 4px}
    .knowlegde-img {margin-right: 0px}
    .testimonial-img {
        margin: auto auto 0px;
    }     
    .single-testimonial {
        padding: 5px 0 0px;
    }    
    .height-100vh {
        height: inherit;
    }    
    .single-slide{
        height: 700px;
    }    
    .slider-content p {
        width: 63%;
    }
    .section-title h3 {
        margin-bottom: 14px;
        padding-bottom: 20px;
    }    
        
    .about-area.three .who-content {
        margin-left: 0px;
    }
    .knowledge-area.pb-100 {
        padding-bottom: 70px;
    }    
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .about-container {
        width: 90%;
    }
    .about-row {
        display: block;
    }
    .about-size {
        width: 90%;
    }
    .education-size {
        width: 90%;
    }
    .slider-content h2, .slider-content h4{font-size: 45px}
    .about-image{margin-right: 0}
    .single-service {padding: 30px 12px 25px}
    .portfolio-menu ul li {margin-right: 12px; font-size: 14px}
    .about-content {margin-left: 0px}
    .image-bg img, .about-image img, .about-area.three .about-image{width: 100%}
    .about-image{margin-bottom: 50px}
    .project-title h3{font-size: 25px}
    .about-area, .service-area, .fun-area, .latest-area, .who-area, .portfolio-area, .about-us-area, .knowledge-area, .team-area, .contact-area {padding-top: 80px} 
    .about-area, .service-area, .fun-area, .latest-area, .who-area, .about-us-area, .knowledge-area, .team-area, .pricing-area {padding-bottom: 80px}
    .about-area.three{padding-top: 0}
    .contact-area{padding-bottom: 40px}
    .about-area.three .who-content{padding-top: 0px; padding-bottom: 0px }
    .testimonial-area {margin-top: 80px; margin-bottom: 80px}
    .portfolio-menu ul {padding: 4px 0 0}
    .portfolio-detail-info p{
        font-size: 9px;
    }
    .about-content, .who-content, .section-tab{margin-right: 0px}
    .two .single-service {padding: 25px 20px}
    .who-content{margin-left: 0}
    .testimonial-content p {font-size: 16px}
    .slider-content h5 {
      margin-top: 0;
    }
    .portfolio-title p {margin-bottom: 50px; padding: 0 100px}
    .single-who{padding-top: 30px;}
    .single-service-three {width: 50%}
    .portfolio-hover h3 {font-size: 15px}
    .portfolio-hover p {font-size: 12px}
    .portfolio-brief-info span {
        top: 60%;
    }
    .blog-area.three .single-blog-content {padding: 20px 12px 30px 30px}
    .map-left, .touch-right {margin-top: 90px; margin-left: 50px}
    .about-area.three .about-image {position: inherit; height: 400px}
    .about-us-content {margin-top: 0}
    .about-us-content h2{display: none}
    .skill-area .skill-bar-item:last-child, .skill-area .skill-bar-item:nth-child(3){display: none}
    .knowledge-content p {margin-bottom: 30px}
    .who-img {margin-left: 0px}
    .testimonial-area-three p{font-size: 30px; line-height: 40px; padding: 0 0px}
    .testimonial-area-three h6::before {left: 36%}
    .about-us-img {margin-left: -95px}
    .single-fun::after{height: 140px; width: 140px}
    .single-fun {height: 200px; width: 200px}
    .single-fun-content {margin-top: 45px}
    .single-fun span{font-size: 26px; margin-bottom: 0}
    .single-fun h3{font-size: 35px; margin-bottom: 0}
    .single-who > img {width: 100%}
    .testimonial-area-three p{font-size: 30px; line-height: 45px;}
    .knowlegde-img {margin-right: 0px}
    .single-testimonial {
        padding: 5px 0 0px;
    }    
    .testimonial-img {
        margin: auto auto 0px;
    }  
    .height-100vh {
        height: inherit;
    }    
    .single-slide{
        height: 650px;
    }    
    .slider-content p {
        width: 63%;
        font-size: 30px;line-height: 35px;
    }    
    .slider-content h2 {
        margin-bottom: 15px; 
    }
    .default-btn {
        margin-top: 25px;
    }
    .about-area.three .who-content {
        margin-left: 0;
    }    
    .about-content {
        margin-top: 0;
    }
    .testimonial-content {
        padding: 70px 100px 0px;
    }   
    .pricing-area {
        padding-bottom: 65px;
    } 
    .blod-details-right-sidebar {
        margin-top: 100px;
    }    
}
 
/* small mobile :320px. */
@media (max-width: 767px) {
    .single-slide {
        background-image: url('img/slider/slider_sm.jpg');
    }
    .main-menu .menu-close {right: 15%}
    .about-area, .service-area, .fun-area, .latest-area, .who-area, .portfolio-area, .about-us-area, .knowledge-area, .team-area, .pricing-area, .contact-area  {padding-top: 60px} 
    .about-area, .service-area, .fun-area, .latest-area, .who-area, .portfolio-area, .touch-area, .about-us-area, .knowledge-area, .team-area, .pricing-area  {padding-bottom: 60px}
    .contact-area{padding-bottom: 30px}
    .single-fun{margin: auto}
    .single-service, .single-fun, .single-blog, .single-member{margin-bottom: 30px}
    .col-md-3:last-child .single-service, .col-md-3:last-child .single-fun, .col-md-4:last-child .single-blog, .col-md-4:last-child .single-member{margin-bottom: 0}
    .project-title h3{
        font-size: 35px;
    }
    .menu-row {
        display: block;
        width: 90%;
        margin: 0 auto;
    }
    .gallery_items{
        display: block;
    }
    .project-title {
        display: block;
        width: 100%;
    }
    .portfolio-menu{ 
        width: 90%;
        margin: 0;
        padding: 15px;
    }
    .portfolio-menu ul {padding: 4px 0 0}
    .logo {
        margin-top: 25px;
      }
    .header-area .menu-open {
        margin: 25px 0;
    }
    .about-container {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0; 
        margin-left: 0;
    }
    .about-row {
        display: block;
    }
    .about-size {
        width: 90%;
    }
    .education-label {
        padding-left: 20px;
    }
    .education-row {
        display: block;
        padding: 20px;
    }
    .footer-size {
        width: 85%;
        margin: 0 auto
    }
    .footer-row {
        display: block;
    }
    .project-title h3, .slider-content h2{font-size: 25px}
    .slider-content h4{margin-top: 70px; font-size: 20px}
    .slider-area-three .slider-content h4 {
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 0;
    }    
    .about-image{margin-right: 0;}
    .single-service {padding: 30px 12px 25px}
    .portfolio-menu ul li {font-size: 13px; margin-right: 14px}
    .about-content, .who-content {margin-left: 0px}
    .image-bg img, .single-who img, .map-area::after, .single-service-three, .about-area.three .about-image, .about-us-img img, .member-img img, .knowlegde-img img, .about-image > img, .single-blog-img img {width: 100%}
    .project-title h3 {margin-bottom: 25px; padding-bottom: 25px}
    .portfolio-menu {margin-bottom: 30px}
    .portfolio-menu ul li {font-size: 14px; margin-bottom: 8px; display: block; text-align: left}
    .slider-content h5 {font-size: 14px; padding-top: 40px; margin-bottom: 14px; font-size: 30px; }
    .slider-content h2 {
        font-size: 40px;
        margin-bottom: 10px;
        padding: 0 40px;
        line-height: 45px;
    }
    .slider-content h5 {
        margin-top: 25px;
    }
    .slider-content p {
        margin-bottom: 10px; 
        font-size: 25px;
        line-height: 35px;
        width: 70%;
    }
    .default-btn {margin-top: 15px;}
    .about-content, .who-content, .section-tab, .touch-left{margin-right: 0px}
    .single-who{padding-top: 30px}
    .portfolio-title p {margin-bottom: 50px; padding: 0 0px}
    .testimonial-content p {font-size: 15px; line-height: 30px;}
    .testimonial-content {padding: 70px 30px 20px}
    .blog-area.three {
        margin-bottom: 60px;
        margin-top: 110px;
    }
    .testimonial-area {
        margin-bottom: 40px;
        margin-top: 30px;
    }
    .two .single-service {padding: 30px 15px}
    .section-tab-menu ul li {margin-right: 15px}
    .portfolio-hover ul li a{font-size: 20px}
    .portfolio-hover ul li {margin-right: 30px}
    .portfolio-hover ul {margin: 0 0 15px}
    .portfolio-hover h3 {font-size: 15px; margin-bottom: 10px}
    .portfolio-hover{top: 40px}
    .testimonial-content span{font-size: 20px; left: 10px; top: 55px}
    .testimonial-content span:last-child {right: 15px}
    .testimonial-img img {height: 210px; width: 210px}
    .map-content {
        margin-top: 0;
    }
    .portfolio-title h2, .who-content h3 {font-size: 22px}
    .portfolio-title p {font-size: 14px}
    .three .slider-content p {padding: 0 0px 0 0}
    .single-service-three {border-right: 1px solid #f6f6f6}
    .about-area.three .who-content {margin-left: 0px; padding-bottom:50px; padding-top: 50px}
    .about-area.three .about-image {position: inherit; height: 300px}
    .about-us-img {margin-left: 0px}
    .blog-title h3 {font-size: 25px}
    .touch-area::after{display: none}
    .touch-left{padding-bottom: 0}
    .about-area.three .who-content {
        padding-top: 0;
        padding-bottom: 0;
    }
    .knowlegde-img {margin-right: 0px}
    .knowledge-content h3 {font-size: 25px}
    .who-img {margin-left: 0px}
    .testimonial-owl.owl-carousel .owl-item img {width: 210px}
    .testimonial-area-three p {
        font-size: 24px;
        line-height: 40px;
        padding: 0;
    }
    .testimonial-area-three h6::before {left: 18%}
    .section-title h3{font-size: 30px}
    .map-left, .touch-right {
        margin-bottom: 60px;
    }
    .map-area::after {
        content: inherit;
    }
    .single-fun {
        left: -5px;
    }
    .grid-item.single-portfolio {
        width: 85%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .single-portfolio:hover .portfolio-detail span, .single-portfolio:hover .portfolio-detail-info{ 
        animation: none;
    }
    .single-portfolio:focus .portfolio-detail span, .single-portfolio:focus .portfolio-detail-info{ 
        animation: none;
    }
    .portfolio-brief-info span {
        font-size: 15px;
        top: 60%;
    }
    .height-100vh {
        height: inherit;
    }    
    .single-slide{
        height: 450px;
    }    
    .main-menu ul li a {
        font-size: 15px;
    }    
    .main-menu ul li ul li a {
        padding: 10px 0;
    }    
    .main-menu ul li:hover > ul {
        left: -203px;
    }    
    .banner-content h2 {
        font-size: 28px;
        margin-top: 50px;
    }
    .banner-content {
        height: 370px;
    }    
    .banner-content-wrapper {
        height: 370px;
    }
    .who-content {
        margin-top: 50px;
    }   
    .about-content {
        margin-top: 30px;
    }    
    .testimonial-area-three.pt-85 {
        padding-top: 0;
    }  
    .blog-details-pra blockquote {
        margin: 26px 0px 26px 0px;
    }    
    .blog-details-top h2 {
        padding-top: 0;
        line-height: 25px;
    }      
    .single-blog-comment.comment-reply {
        margin-left: 0;
    }    
    .blod-details-right-sidebar {
        margin-top: 50px;
    }     
    .comment-title-date {
        display: block;
    }    
    .blog-details-thumb-wrap {
        margin-bottom: 20px;
    }   
    .recent-thumb {
        display: block;
    }    
    .ptb-100 {
        padding: 50px 0;
    }    
    .single-blog-comment {
        margin-top: 25px;
    }    
    .mt-60 {
        margin-top: 35px;
    } 
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .single-service {padding: 40px 20px 35px}
    .portfolio-menu ul li {font-size: 13px; margin-right: 14px}
    .testimonial-area-three h6::before {left: 35%}
    .slider-content h2 {margin-bottom: 15px; }
    .slider-content p {font-size: 30px;line-height: 35px;width: 70%;}
    .default-btn {margin-top: 25px;}
}